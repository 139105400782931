import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import './Swiper.scss';

const CustomSwiper = ({ onClose, items }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className={classnames('custom-swiper-wrapper', {
      loaded,
    })}
    >
      <div className="close-wrapper">
        <div onClick={onClose}>
          <img src="/images/close.svg" alt="close-modal" />
        </div>
      </div>
      <Swiper
        spaceBetween={40}
        slidesPerView="auto"
        loop
        centeredSlides
        onSwiper={(swiper) => {
          setTimeout(() => {
            swiper.slideNext();
            setLoaded(true);
          }, 700);
        }}
      >
        {
            items.map((el) => (
              <SwiperSlide key={`slider-${el.id}`}>
                <img src={el.fullImageSrc} alt="" className="img-responsive slider-image" />
              </SwiperSlide>
            ))
        }
      </Swiper>
    </div>
  );
};

CustomSwiper.propTypes = {
  onClose: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    fullImageSrc: PropTypes.string,
  })),
};

CustomSwiper.defaultProps = {
  items: [],
};

export default CustomSwiper;
