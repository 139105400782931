import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

import RealizationsNav from 'components/RealizationsNav';

import './Realizations.scss';

const Realizations = () => (
  <div className="realizations-page">
    <div className="line" />
    <div>
      <h1>
        <FormattedMessage id="realizationsPageTitle" />
      </h1>
      <div className="description-text">
        <FormattedMessage id="realizationsPageSubtitle" />
      </div>
    </div>
    <RealizationsNav />
  </div>
);

export default Realizations;
