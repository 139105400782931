import React, { useState } from 'react';
import classnames from 'classnames';

import BoxNav from 'components/BoxNav';
import Swiper from 'components/Swiper';
import './RealizationsNav.scss';

const categories = [{
  id: 'category-1',
  boxName: 'realizationCategoryOffice',
  images: [{
    id: 'category-1-image-1',
    imageSrc: '/images/realizations/office/panel-eho-biuro-4-miniatura.jpg',
    fullImageSrc: '/images/realizations/office/panel-eho-biuro-4.jpg',
    classes: ['item', 'item-50'],
  }, {
    id: 'category-1-image-2',
    imageSrc: '/images/realizations/office/panel-eho-biuro-3-miniatura.jpg',
    fullImageSrc: '/images/realizations/office/panel-eho-biuro-3.jpg',
    classes: ['item', 'item-25'],
  }, {
    id: 'category-1-image-3',
    imageSrc: '/images/realizations/office/panel-eho-biuro-2-miniatura.jpg',
    fullImageSrc: '/images/realizations/office/panel-eho-biuro-2.jpg',
    classes: ['item', 'item-25'],
  }, {
    id: 'category-1-image-4',
    imageSrc: '/images/realizations/office/panel-eho-biuro-detal-miniatura.jpg',
    fullImageSrc: '/images/realizations/office/panel-eho-biuro-detal.jpg',
    classes: ['item', 'item-25'],
  }, {
    id: 'category-1-image-5',
    imageSrc: '/images/realizations/office/Izba-lekarska-1-e1574080922729.jpg',
    fullImageSrc: '/images/realizations/office/Izba-lekarska-1-e1574080922729_full.jpg',
    classes: ['item', 'item-25'],
  }, {
    id: 'category-1-image-6',
    imageSrc: '/images/realizations/office/panel-eho-biuro-1-miniatura.jpg',
    fullImageSrc: '/images/realizations/office/panel-eho-biuro-1.jpg',
    classes: ['item', 'item-50'],
  }, {
    id: 'category-1-image-7',
    imageSrc: '/images/realizations/office/panel-eho-biuro-5-miniatura.jpg',
    fullImageSrc: '/images/realizations/office/panel-eho-biuro-5.jpg',
    classes: ['item', 'item-50', 'fix-top'],
  }],
}, {
  id: 'category-2',
  boxName: 'realizationCategoryHome',
  images: [{
    id: 'category-2-image-1',
    imageSrc: '/images/realizations/home/realizacje-6-1.jpg',
    fullImageSrc: '/images/realizations/home/realizacje-6-1.jpg',
    classes: ['item', 'item-50'],
  }, {
    id: 'category-2-image-2',
    imageSrc: '/images/realizations/home/realizacje-5-1.jpg',
    fullImageSrc: '/images/realizations/home/realizacje-5-1.jpg',
    classes: ['item', 'item-25', 'pad-25'],
  }, {
    id: 'category-2-image-3',
    imageSrc: '/images/realizations/home/realizacje-4-1.jpg',
    fullImageSrc: '/images/realizations/home/realizacje-4-1.jpg',
    classes: ['item', 'item-25'],
  }, {
    id: 'category-2-image-4',
    imageSrc: '/images/realizations/home/realizacje-3-1.jpg',
    fullImageSrc: '/images/realizations/home/realizacje-3-1.jpg',
    classes: ['item', 'item-25'],
  }, {
    id: 'category-2-image-5',
    imageSrc: '/images/realizations/home/realizacje-2-1.jpg',
    fullImageSrc: '/images/realizations/home/realizacje-2-1.jpg',
    classes: ['item', 'item-25', 'pad-25'],
  }, {
    id: 'category-2-image-6',
    imageSrc: '/images/realizations/home/realizacje-1-1.jpg',
    fullImageSrc: '/images/realizations/home/realizacje-1-1.jpg',
    classes: ['item', 'item-25'],
  }, {
    id: 'category-2-image-7',
    imageSrc: '/images/realizations/home/IMG_20191113_095911_599-e1574080074608.jpg',
    fullImageSrc: '/images/realizations/home/IMG_20191113_095911_599-e1574080074608.jpg',
    classes: ['item', 'item-25'],
  }],
}, {
  id: 'category-3',
  boxName: 'realizationCategorySchool',
  images: [{
    id: 'category-3-image-1',
    imageSrc: '/images/realizations/school/panel-eho-szkola-3-miniatura.jpg',
    fullImageSrc: '/images/realizations/school/panel-eho-szkola-3.jpg',
    classes: ['item', 'item-50'],
  }, {
    id: 'category-3-image-2',
    imageSrc: '/images/realizations/school/panel-eho-szkola-2-miniatura.jpg',
    fullImageSrc: '/images/realizations/school/panel-eho-szkola-2.jpg',
    classes: ['item', 'item-50'],
  }, {
    id: 'category-3-image-3',
    imageSrc: '/images/realizations/school/panel-eho-szkola-4-miniatura.jpg',
    fullImageSrc: '/images/realizations/school/panel-eho-szkola-4.jpg',
    classes: ['item', 'item-25', 'clear-left'],
  }, {
    id: 'category-3-image-4',
    imageSrc: '/images/realizations/school/panel-eho-szkola-1-miniatura.jpg',
    fullImageSrc: '/images/realizations/school/panel-eho-szkola-1.jpg',
    classes: ['item', 'item-25'],
  }],
}, {
  id: 'category-4',
  boxName: 'realizationCategoryRestaurant',
  images: [{
    id: 'category-4-image-1',
    imageSrc: '/images/realizations/restaurant/panel-eho-restauracja-1-miniatura.jpg',
    fullImageSrc: '/images/realizations/restaurant/panel-eho-restauracja-1.jpg',
    classes: ['item', 'item-50'],
  }, {
    id: 'category-4-image-2',
    imageSrc: '/images/realizations/restaurant/panel-eho-restauracja-2-miniatura.jpg',
    fullImageSrc: '/images/realizations/restaurant/panel-eho-restauracja-2.jpg',
    classes: ['item', 'item-50'],
  }, {
    id: 'category-4-image-3',
    imageSrc: '/images/realizations/restaurant/20190614_221609.jpg',
    fullImageSrc: '/images/realizations/restaurant/20190614_221609_full.jpg',
    classes: ['item', 'item-25'],
  }, {
    id: 'category-4-image-4',
    imageSrc: '/images/realizations/restaurant/20190614_211740.jpg',
    fullImageSrc: '/images/realizations/restaurant/20190614_211740_full.jpg',
    classes: ['item', 'item-25'],
  }],
}, {
  id: 'category-5',
  boxName: 'realizationCategoryPublic',
  images: [{
    id: 'category-5-image-1',
    imageSrc: '/images/realizations/public/eho_publiczne_s.jpg',
    fullImageSrc: '/images/realizations/public/eho_publiczne_s.jpg',
    classes: ['item', 'item-50'],
  }, {
    id: 'category-5-image-2',
    imageSrc: '/images/realizations/public/20190614_214212-e1574080473358.jpg',
    fullImageSrc: '/images/realizations/public/20190614_214212-e1574080473358.jpg',
    classes: ['item', 'item-25'],
  }],
}];

const RealizationsNav = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [showSlider, setShowSlider] = useState(false);

  return (
    <div className="realizations-nav">
      <BoxNav
        boxes={categories}
        activeItemIndex={activeItemIndex}
        setActiveItemIndex={setActiveItemIndex}
      />
      <div className="items-wrapper">
        {
          categories[activeItemIndex].images.map((el) => (
            <div
              className={classnames(...el.classes)}
              key={el.id}
              onClick={() => { setShowSlider(true); }}
            >
              <img src={el.imageSrc} alt="" className="img-responsive" />
            </div>
          ))
        }
      </div>
      {
        showSlider && (
          <Swiper
            onClose={() => {
              setShowSlider(false);
            }}
            items={categories[activeItemIndex].images}
          />
        )
      }
    </div>
  );
};

export default RealizationsNav;
