import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FormattedMessage } from 'gatsby-plugin-intl';

import './BoxNav.scss';

const BoxNav = ({ boxes, activeItemIndex, setActiveItemIndex }) => (
  <div className="box-nav">
    <div className="navigation-wrapper always-inline">
      {
          boxes.map((el, key) => (
            <div
              className={classnames('navigation-button', {
                active: key === activeItemIndex,
              })}
              key={el.id}
              onClick={() => {
                setActiveItemIndex(key);
              }}
            >
              <FormattedMessage id={el.boxName} />
            </div>
          ))
        }
    </div>
  </div>
);

BoxNav.propTypes = {
  activeItemIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setActiveItemIndex: PropTypes.func.isRequired,
  boxes: PropTypes.arrayOf(PropTypes.shape({
    boxName: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })),
};

BoxNav.defaultProps = {
  boxes: [],
};

export default BoxNav;
